import { escape, unescape } from "lodash";

/**
 * Removes html tags from the provided content to allow for safe .innerHTML update.
 *
 * @param {string} [content] The content to sanitize
 * @returns Sanitized content
 */
export function sanitize(content) {
  if (!content || typeof content !== "string") return content;
  const div = document.createElement("div");
  div.innerHTML = unescape(content);
  return escape(div.textContent);
}

/**
 * Set disabled class and attribute to an element.
 *
 * @param {HTMLElement} el
 */
export function setDisabled(el) {
  el.classList.add("disabled");
  el.setAttribute("disabled", "disabled");
  el.closest(".input-base")?.classList.add("disabled");
}

/**
 * Remove disabled class and attribute from an element.
 *
 * @param {HTMLElement} el
 */
export function removeDisabled(el) {
  el.classList.remove("disabled");
  el.removeAttribute("disabled");
  el.closest(".input-base")?.classList.remove("disabled");
}

/**
 * Get an element by selector or actual node element.
 *
 * @param {string | HTMLElement} el
 */
export function getElement(el) {
  return typeof el === "string" ? document.querySelector(el) : el;
}

/**
 * Dispatches an event manually to the given element.
 *
 * @param {HTMLElement} el
 * @param {string} type The type of event to fire. Eg. "change"
 */
export function dispatchManualEvent(el, type) {
  if ("createEvent" in document) {
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent(type, false, true);
    el.dispatchEvent(evt);
  } else {
    el.fireEvent(`on${type}`);
  }
}

export function getHTMLAttribute(attribute, value) {
  if (typeof value === "undefined" || value === null) return "";
  return `${attribute}="${value}"`;
}

/**
 * @param {boolean} predicate The condition to check.
 * @param {string} html The html to return if the condition is true.
 * @param {string} [otherHtml] The html to return if the condition is false.
 * @returns {string}
 */
export function createHTML(predicate, html, otherHtml = "") {
  return predicate ? html : otherHtml;
}
