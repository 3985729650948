const uuid = require("uuid").v4;
const toastTypeStyles = {
  success: {
    faIcon: "fa-check-circle",
    faIconStyles: "color: #22B69E;",
    borderColor: "#04C47A"
  },
  error: {
    faIcon: "fa-exclamation-circle",
    faIconStyles: "color: #FF2E25;",
    borderColor: "#FF2E25"
  },
  warning: {
    faIcon: "fa-exclamation-triangle",
    faIconStyles: "color: #FFA600;",
    borderColor: "#F1C471"
  },
  info: {
    faIcon: "fa-info-circle",
    faIconStyles: "color: #4464FC;",
    borderColor: "#4464FC"
  }
};

const toast = {
  initialize(optionsObj) {
    $(".toast").toast(
      optionsObj || { animation: false, autohide: true, delay: 6000 }
    ); // initialise all toasts
  },

  /**
   * All inclusive function to remove all existing toasts, create a new toast, apply it to the body, initialize it and display it.
   * @param {object} param0
   * @param {keyof toastTypeStyles} param0.type
   * @param {string} param0.titleText text for the title
   * @param {string} param0.bodyMessageText
   * @param {boolean} [param0.suppressDismiss] dont show the toast dismiss icon
   * @param {object} [param0.initializeConfigObj] initialize config (https://getbootstrap.com/docs/4.3/components/toasts/#usage)
   * @param {Array<{bindEventName: string, eventAction: Function}>} param0.eventBindings which event we're binding to. 'show.bs.toast' / 'shown.bs.toast' / 'hide.bs.toast' / 'hidden.bs.toast' - https://getbootstrap.com/docs/4.3/components/toasts/#events
   */
  displayToast({
    type,
    titleText,
    bodyMessageText,
    suppressDismiss,
    initializeConfigObj,
    eventBindings
  }) {
    const toastId = uuid();
    const { toastEl } = this.buildToastElement(
      type,
      toastId,
      titleText,
      bodyMessageText,
      suppressDismiss
    );

    jQuery("body").append(toastEl);

    this.initialize(
      initializeConfigObj || { animation: true, autohide: true, delay: 6000 }
    );
    this.handleToastEventBindings(eventBindings, toastId);

    // immediately show this toast after creation
    this.showToast(toastId);
  },

  buildToastElement(
    type,
    toastId,
    titleText,
    bodyMessageText,
    suppressDismiss
  ) {
    if (!type) {
      // no style specified, default to success
      type = "success";
    }

    const toastEl = `
      <div class="toast-container">
        <div
          class="toast"
          id="${toastId || ""}"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style="border-left: 6px solid ${toastTypeStyles[type].borderColor};"
        >
          <div class="toast-header">
            <i class="fas ${toastTypeStyles[type].faIcon} mr-2" style="${
      toastTypeStyles[type].faIconStyles
    }"></i>
            <strong>${titleText || "Action complete"}</strong>
          </div>
          <div class="toast-body" style="">
            ${bodyMessageText}
          </div>
          <button type="button" class="close ${
            suppressDismiss ? "d-none" : ""
          }" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    `;
    return { toastEl, type };
  },

  handleToastEventBindings(eventBindings, toastId) {
    // Toast event bindings (if applicable)
    if (eventBindings && toastId) {
      // to bind events we must give the toast an ID
      if (eventBindings.length > 0) {
        // we have some defined actions
        for (const action of eventBindings) {
          // iterate through each defined action
          if (action.bindEventName && action.eventAction) {
            $(`#${toastId}`).on(action.bindEventName, action.eventAction);
          }
        }
      }
    }
  },

  showToast(toastId) {
    // display all toasts in dom
    jQuery(`#${toastId}`).toast("show");
  },

  clearToast(toastId) {
    // clear a specific toast in dom
    jQuery(`#${toastId}`).toast("hide");
  },

  hideToast() {
    // hide all toasts in dom
    jQuery(".toast").toast("hide");
  },

  disposeToast() {
    // dispose all toasts in dom
    jQuery(".toast").toast("dispose");
  }
};

export default toast;
